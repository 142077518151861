.impressum-container {
  font-size: 20px;

  .header {
    text-align: center;
    font-size: var(--title-font-size-mobile);
    font-weight: 400;
  }

  .wrapper {
    display: grid;
    width: 100%;
    gap: 40px;

    .left-side {
      gap: 10px;
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        > svg {
          width: 20px;
        }
      }

      .company-name {
        font-size: 25px;
      }
    }

    .right-side {
      .social-media {
        display: flex;
        gap: 30px;
        border-radius: 50%;

        > a:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .impressum-container {
    font-size: 19px;

    .header {
      font-size: var(--title-font-size);
    }

    .wrapper {
      display: flex;
      width: 100%;

      .left-side {
        width: 100%;

        > div {
          > svg {
            width: 20px;
          }
        }

        .company-name {
          font-size: var(--subtitle-font-size);
        }
      }

      .right-side {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .social-media {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-content: center;
          gap: 20px;

          > a:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
